$( window ).on( "load", function() {
	$( ".hero__btn--ahchor" ).on( "click", "a", function( event ) {
		event.preventDefault();
		let id  = $( this ).attr( "href" );
		let top = $( id ).offset().top;
		$( "body,html" ).animate( { scrollTop: top - 40 }, 2000 );
	} );

	function lazyLoadVideo() {
		let video = $( ".hero-video-js video" );
		if ( video.length === 0 ) {
			return;
		}
		let source = video.find( "source" );
	
		let posterImage = new Image();
		posterImage.src = video.attr( "poster" );
	
		let dataSrc = source.attr( "data-src" );
		source.attr( "src", dataSrc );
	
		video[0].load();
	}
	
	function lazyLoadPoster() {
		let video = $( ".hero-video-js video" );
		if ( video.length === 0 ) {
			return;
		}

		let posterImage = new Image();
		posterImage.src = video.attr( "data-poster" );

		posterImage.onload = function() {
			video[0].poster = posterImage.src;
		};

		posterImage.src = video.attr( "data-poster" );
	}
	
	lazyLoadVideo();
	lazyLoadPoster();

} );